import React from 'react';
import aboutHome from '../assets/technology.jpg';
import { Helmet } from 'react-helmet';

function Service() {
	return (
		<>
			<Helmet>
				<title>Skyapps ID</title>
				<meta name="description" content="Menghadirkan teknologi yang merata dan inklusif untuk semua." />
				<meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
				<meta name="keywords" content="IoT, Internet of Things, ESP32, smart devices, real-time data, sensor dashboard, temperature sensor, humidity sensor, IoT technology, smart home, programming, software development, cloud engineering, cloud computing, DevOps, DevOps practices, CI/CD pipelines, Docker, Kubernetes, Infrastructure as Code, monitoring and logging" />
			</Helmet>

			<div className="relative bg-gradient-to-r from-slate-950 to-slate-800 text-white text-center py-14">
				<div className="mx-auto px-6 md:px-14 mb-14 md:mb-28 lg:mb-44">
					<h2 className="text-2xl md:text-2xl font-bold mb-2">Delivering equitable and inclusive technology for everyone.</h2>
					<p className="text-gray-200 mt-2">Menghadirkan teknologi yang merata dan inklusif untuk semua.</p>
				</div>

				<div className="absolute inset-x-0 -bottom-1 md:-bottom-2 lg:-bottom-5">
					<svg className="w-full h-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
						<path fill="#ffffff" d="M0,224L30,213.3C60,203,120,181,180,160C240,139,300,117,360,112C420,107,480,117,540,138.7C600,160,660,192,720,213.3C780,235,840,245,900,224C960,203,1020,149,1080,138.7C1140,128,1200,160,1260,165.3C1320,171,1380,149,1410,138.7L1440,128L1440,320L0,320Z"></path>
					</svg>
				</div>
			</div>

			<div className="container mx-auto grid grid-cols-1 lg:grid-cols-2 gap-24 p-5 mt-0 mb-16">
				<div className="text-start md:text-left mt-24">
					<h1 className="text-3xl font-bold text-gray-800">
						Skyapps ID
					</h1>
					<p className="text-gray-500 mt-4">
						Membuka akses yang setara terhadap kemajuan digital dan inovasi untuk meningkatkan kualitas hidup, dan membuka peluang baru bagi semua.
					</p>
				</div>
				<div className="hidden md:block text-start md:text-left">
					<img src={aboutHome} alt="" className="w-auto" />
				</div>
			</div>
		</>
	);
}

export default Service;
