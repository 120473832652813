

import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const icon = new L.Icon({
    iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
    shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
});

function RecenterMap({ lat, lng, zoom }) {
    const map = useMap();
    useEffect(() => {
        map.flyTo([lat, lng], zoom, {
            animate: true,
            duration: 1,
        });
    }, [lat, lng, zoom, map]);

    return null;
}

const Gps = () => {
    const [connected, setConnected] = useState(false);
    const [latitude, setLatitude] = useState(-2.50);
    const [longitude, setLongitude] = useState(117.90);
    const [gpsFixType, setGpsFixType] = useState(0);
    const [speedKmh, setSpeedKmh] = useState(0);
    const [zoom, setZoom] = useState(5);

    useEffect(() => {
        const storedToken = localStorage.getItem('token_ws');

        if (!storedToken) {
            const input = prompt("Please enter your token_ws:");
            if (input) {
                localStorage.setItem('token_ws', input);
            }
        }

        const mqttUrl = 'wss://skyapps.id/mqtt';
        const mqttClient = mqtt.connect(mqttUrl, {
            clientId: 'web_' + Math.random().toString(36).slice(2, 2 + 6),
            username: 'web',
            password: localStorage.getItem('token_ws'),
        });

        mqttClient.on('connect', () => {
            setConnected(true);

            mqttClient.subscribe('device/+/telemetry/gps', (err) => {
                if (err) {
                    console.error('Subscription error:', err);
                } else {
                    console.log('Subscribed to topic');
                }
            });
        });

        mqttClient.on('message', (topic, payload) => {
            console.log(`Received message from topic ${topic}:`, payload.toString());
            const json = JSON.parse(payload.toString())
            setLatitude(json.latitude.toFixed(3));
            setLongitude(json.longitude.toFixed(3));
            setGpsFixType(json.gps_fix_type);
            setSpeedKmh(json.speed_kmh);
            setZoom(16);
        });

        mqttClient.on('error', (err) => {
            console.error('Connection error:', err);
        });

        return () => {
            mqttClient.end();
        };
    }, []);

    return (
        <div>
            {/* <h1>MQTT Client</h1> */}
            <MapContainer dragging={false} center={[latitude, longitude]} zoom={zoom} style={{ height: "600px", width: "100%" }}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />
                <RecenterMap lat={latitude} lng={longitude} zoom={zoom} />
                {zoom > 5 && <Marker position={[latitude, longitude]} icon={icon}>
                    <Popup>
                        Status: {connected ? 'Connected' : 'Disconnected'}
                        <br />
                        Latitude: {latitude}
                        <br />
                        Longitude: {longitude}
                        <br />
                        Spped Kmh: {speedKmh}
                        <br />
                        Gps Fix: {gpsFixType}
                    </Popup>
                </Marker>}
            </MapContainer>

        </div>
    );
};

export default Gps;
