import React, { useState, useEffect } from 'react';
import logo from '../assets/logo.png';
// import { Link } from 'react-router-dom';

function Header() {
	const [isOpen, setIsOpen] = useState(false);
	const toggleMenu = () => setIsOpen(!isOpen);
	const [logoClass, setLogoClass] = useState('h-14');

    const handleScroll = () => {
        if (window.scrollY > 100) {
			setLogoClass('h-9 transition-all duration-300 ease-in-out');
        } else {
			setLogoClass('h-14 transition-all duration-300 ease-in-out');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

	return (
		<header className="bg-gradient-to-r from-slate-950 to-slate-800 text-white py-4 px-4 sticky top-0 z-50 text-sm">
			<div className="container mx-auto flex justify-between items-center">
				<a href="/" className="flex justify-center items-center">
					<img src={logo} alt="Logo" className={`transition-all duration-300 ease-in-out ${logoClass}`} />
				</a>

				{/* <div className="md:hidden">
					<button onClick={toggleMenu} className="focus:outline-none">
						<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
						</svg>
					</button>
				</div> */}

				{/* <nav className="hidden md:flex md:space-x-4">
					<Link to={"/"} className="py-2 hover:text-gray-300">Home</Link>
					<Link to={"/about"} className="py-2 hover:text-gray-300">Tentang Kami</Link>
					<Link to={"/services"} className="py-2 hover:text-gray-300">Service</Link>
					<Link to={"/contact"} className="py-2 hover:text-gray-300">Kontak</Link>
				</nav> */}
			</div>

			<div className={`fixed top-0 left-0 h-full bg-red-600 transition-transform transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} w-64 z-40`}>
				<div className="flex justify-between items-center p-4">
					<h2 className="text-xl text-white">Menu</h2>
					<button onClick={toggleMenu} className="focus:outline-none">
						<svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
							<path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
						</svg>
					</button>
				</div>
				{/* <nav className="mt-4 flex flex-col space-y-2">
					<Link to={"/"} className="block py-2 px-4 hover:bg-red-500" onClick={toggleMenu}>Home</Link>
					<Link to={"/about"} className="block py-2 px-4 hover:bg-red-500" onClick={toggleMenu}>About</Link>
					<Link to={"/services"} className="block py-2 px-4 hover:bg-red-500" onClick={toggleMenu}>Service</Link>
					<Link to={"/contact"} className="block py-2 px-4 hover:bg-red-500" onClick={toggleMenu}>Contact</Link>
				</nav> */}
			</div>

			{isOpen && (
				<div
					onClick={toggleMenu}
					className="fixed inset-0 bg-black opacity-50 z-30"
				></div>
			)}
		</header>
	);
}

export default Header;
