import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../views/Home';
import Gps from '../views/Gps';

function Content() {
	return (
		<>
			<Header />
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/live-gps" element={<Gps />} />
			</Routes>
			<Footer />
		</>

	);
}

export default Content;
