import React from 'react';
import logo from '../assets/logo.png';

function Footer() {
	return (
		<footer className="bg-gradient-to-r from-slate-950 to-slate-800 text-white py-8">
			<div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-24 p-8">
				<div className="text-start md:text-left">
					<img src={logo} alt="IndiHome Logo" className="mb-4 h-20" />
					<div className="flex justify-start md:justify-start space-x-4 mt-4  ml-2">
						<a href="/#" className="bg-white text-slate-900 p-1 rounded-full">
							<span className="material-icons mt-1 ml-1 mr-1">photo_camera</span>
						</a>
						<a href="/#" className="bg-white text-slate-900 p-1 rounded-full">
							<span className="material-icons mt-1 ml-1 mr-1">facebook</span>
						</a>
						<a href="/#" className="bg-white text-slate-900 p-1 rounded-full">
							<span className="material-icons mt-1 ml-1 mr-1">videocam</span>
						</a>
						<a href="/#" className="bg-white text-slate-900 p-1 rounded-full">
							<span className="material-icons mt-1 ml-1 mr-1">play_circle_filled</span>
						</a>
						<a href="/#" className="bg-white text-slate-900 p-1 rounded-full">
							<span className="material-icons mt-1 ml-1 mr-1">email</span>
						</a>
					</div>
				</div>

				<div className="text-start md:text-left mt-5">
					<h3 className="text-lg font-semibold mb-2">Contact Us</h3>
					<ul>
						<li className="mb-2 flex items-center">
							<span className="material-icons mr-2">email</span> Email: -
						</li>
					</ul>
				</div>
			</div>

			<div className="container mx-auto border-t border-white-700 mt-8 pt-5">
				<p className="text-center text-sm">&copy;2024 Skyapps</p>
			</div>
		</footer>
	);
}

export default Footer;
